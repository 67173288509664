import Vue from 'vue';
import VueRouter from 'vue-router';
import { getToken } from '@/utils/auth';
import BaseLayout from '../views/BaseLayout.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'BaseLayout',
    component: BaseLayout,
    children: [
      {
        path: '/',
        redirect: '/creditApplyList',
      },
      {
        path: '/user',
        name: 'User',
        component: () => import(/* webpackChunkName: "User" */ '@/views/User'),
      },
      {
        path: '/creditApplyList',
        name: 'CreditApplyList',
        component: () => import(/* webpackChunkName: "creditApplyList" */ '@/views/CreditApplyList'),
      },
      {
        path: '/loanList',
        name: 'LoanList',
        component: () => import(/* webpackChunkName: "loanList" */ '@/views/LoanList'),
      },
      {
        path: '/repayList',
        name: 'RepayList',
        component: () => import(/* webpackChunkName: "repayList" */ '@/views/RepayList'),
      },
      {
        path: '/fundPartners',
        name: 'FundPartners',
        component: () => import(/* webpackChunkName: "user" */ '@/views/FundPartners'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  /* 必须调用 `next` */

  if (!getToken() && to.path !== '/login') {
    next({
      path: '/login',
      replace: true,
    });
    return;
  }

  next();
});
export default router;
