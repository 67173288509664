<template>
  <a-layout class="page-container" id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible theme="light">
      <div class="logo">信贷后台</div>
      <a-menu
        @click="menuHandler"
        theme="light" mode="inline" :default-selected-keys="defaultKeys">
        <a-menu-item key="/user">
          <a-icon type="user-add" />
          <span>添加用户</span>
        </a-menu-item>
        <a-menu-item key="/creditApplyList">
          <a-icon type="idcard" />
          <span>授信申请</span>
        </a-menu-item>
        <a-menu-item key="/loanList">
          <a-icon type="hdd" />
          <span>借款订单</span>
        </a-menu-item>
        <a-menu-item key="/repayList">
          <a-icon type="transaction" />
          <span>还款订单</span>
        </a-menu-item>
        <a-menu-item key="/fundPartners">
          <a-icon type="property-safety" />
          <span>资金方</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header class="nav-header">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />

        <div class="header-user">
          <div class="header-username">欢迎使用，{{ accountInfo }}</div>
        </div>
        <a class="logout" @click="logout">退出登录</a>
      </a-layout-header>
      <a-layout-content
        :style="{ margin: '24px 16px', padding: '6px 12px', minHeight: '280px' }"
      >
        <router-view/>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { removeToken } from '@/utils/auth';

export default {
  data() {
    return {
      collapsed: false,
      defaultKeys: [],
    };
  },
  methods: {
    menuHandler({ item, key, keyPath }) {
      console.log('click menu item', item, key, keyPath, this.$route.path);
      if (this.$route.path === key) {
        return;
      }
      this.$router.push(key);
    },
    logout() {
      removeToken();
      this.$router.push({
        path: '/login',
        redirect: true,
      });
    },
  },
  computed: {
    accountInfo() {
      return this.$store.getters.accountInfo;
    },
  },
  created() {
    this.defaultKeys = [this.$route.path];
    console.log(this.defaultKeys);
  },
};
</script>
<style lang="scss">
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  padding-left: 4px;
  line-height: 32px;
}

.ant-layout.page-container {
  min-height: 100%;
}

.nav-header {
  background: #fff !important;
  display: flex;
  padding: 0 30px 0 0 !important;
  align-items: center;

  .header-user {
    flex: 1;
    text-align: right;
    color: rgba(0, 0, 0, .65);
  }

  .logout {
    margin-left: 16px;
  }
}
</style>
